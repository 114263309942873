
import { Component, Mixins } from 'vue-property-decorator';
import store from '@/store';
import Home from '@/mixins/Home';
import HomeMainVisual from '@/components/home/HomeMainVisual/HomeMainVisual.vue';
import HomeSlider from '@/components/home/HomeSlider/HomeSliderEn.vue';
import NaviSerchnavi from '@/components/navi/NaviSerchnavi/NaviSerchnaviEn.vue';
import HomeFavoritelist from '@/components/home/HomeFavoritelist/HomeFavoritelistEn.vue';
import HomeHistory from '@/components/home/HomeHistory/HomeHistoryEn.vue';
import HomeSns from '@/components/home/HomeSNS/HomeSNSEn.vue';

@Component({
  components: {
    HomeMainVisual,
    HomeSlider,
    NaviSerchnavi,
    HomeFavoritelist,
    HomeHistory,
    HomeSns,
  },
})
export default class HomeEn extends Mixins(Home) {
  private async mounted() {
    const refs: any = this.$refs;
    await Promise.all([
      refs.slider.getEntries(),
      refs.favoritelist.getEntries(),
      refs.historylist.getEntries(),
    ]);
    this.loaded = true;
    store.commit('loaded', true);
  }
}
